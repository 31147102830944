import React from 'react';
import styled from 'styled-components';

import Table from 'modules/ShiftDashboard/Table';

const Wrapper = styled.div`
  widTh: 20rem;
  background: #343434;
  height: 30.5rem;
`;
const TableWrapper = styled.div`
  height: 18rem;
  border-bottom: 1px solid #696969;
`;
const Efficiency = styled.div`
  display: flex;
  margin-top: 0.75rem;
`;
const EItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
`;
const Heading = styled.div`
  font-size: 3rem;
`;
const Value = styled.div`
  font-size: 4.5rem;
`;
function LeftBar() {
  return <Wrapper>
    <TableWrapper>
      <Table
        headers={['Line', 'LEF', 'CPM']}
        items={[
          ['CL61', '72', '2,200'], 
          ['CL62', '15', '120'], 
        ]}
      />
    </TableWrapper>
    <Efficiency>
      <EItem>
        <Heading>UT%</Heading>
        <Value>20</Value>
      </EItem>
      <EItem>
        <Heading>LEF</Heading>
        <Value>72</Value>
      </EItem>
    </Efficiency>
  </Wrapper>
}

export default LeftBar;