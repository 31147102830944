import React from 'react';
import styled from 'styled-components';

// const imgSrc = 'https://s3-alpha-sig.figma.com/img/3cdf/5532/b114a227f6da8d95afb71f393cc828f1?Expires=1610323200&Signature=T5SBWLOek4XWDHr5Qo4CymFA23BTzUoi5yEw9GNsiyajUYLXdPms0Y4KF6tOSj98CX77rxyIwRaoeNkxPPLoVWMAewWeFAybp68asvJdAWZxdmDmZczLM6DntZDU681rwpYcW54q~JGykxQoQI7opL-q4jiMGTdyHaUSVBRoc1kE0UUS64MouPxm7ChglcbM5VPiBuOu6sBDJ7RL35BBl7gh3k-X1l2uQ6hkhqWGZq5KdUGsliRwVr~03qJZAJk33Ouq0H3OGm5D0ZMa1oGlKquix~w5L7eCTOUq2NeJpw0YVza67swSzAVbtH2FtWrrke8CmlfcoMP8CgzM0gCf-g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
  // background: url(${imgSrc});
//
type ImgProps = {
  src: string,
}
const Img = styled.div<ImgProps>`
  width: 56px;
  height: 56px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-left: 16px;
  background-image: url('${({src}) => src}');
`
function RoundIcon({src}: {src: string}) {
  return <Img src={`/images/incidents/src_${src}.png`} />
}

export default RoundIcon;