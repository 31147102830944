import React, { useState } from 'react';
import styled from 'styled-components';

import Videos from 'modules/ShiftDashboard/Videos';

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 21rem);
  height: 30rem;
  background: #343434;
  margin-left: 0.5rem;
`;
const Header = styled.div`
  background: #131313;
  height: 3rem;
  display: flex;
  align-items: center;
`;
const Live = styled.div`
  color: #FF0000;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.75rem;
`;
const Dot = styled.div`
  width: 14px;
  height: 14px;
  background: #FF0000;
  border-radius: 50%;
  margin-left: 4px;
`;
const Filler = styled.div`
  margin-left: 0.75rem;
  color: #D6D6D6;
`;
function Content() {
  return <Wrapper>
    <Header>
      <Live>LIVE <Dot /> <Filler>Filler</Filler></Live>
    </Header>
    <Videos />    
  </Wrapper>
}

export default Content;