import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  position: relative;
  width: ${({theme}) => theme.sidebar.width};
  background: ${({theme}) => theme.sidebar.bg};
  color: ${({theme}) => theme.sidebar.color};
`;
const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
  &:hover {
    background: ${({theme}) => theme.sidebar.itemHoverBg};
  }
  & > a {
    
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    width: 100%;
    height: 100%;
  }
  & > a:active {
    background: ${({theme}) => theme.sidebar.itemHoverBg};
  }
`;

// padding: ${({theme}) => theme.paddings.block};
//
const Icon = styled.img`
  width: 24px;
`;

const BottomIcons = styled.div`
  position: absolute;
  bottom: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;

`
function Sidebar() {
  return <Wrapper>
    <Item><Link to="/dashboard"><Icon src='/icons/eye.svg' /></Link></Item>
    <Item><Link to="/incidents"><Icon src='/icons/Incident.svg' /></Link></Item>

    <Item><Icon src='/icons/cpu.svg' /></Item>
    <Item><Icon src='/icons/pie-chart.svg' /></Item>
    <Item><Icon src='/icons/map.svg' /></Item>

    <BottomIcons>
    <Item><Icon src='/icons/settings.svg' /></Item>
    <Item><Icon src='/icons/help-circle.svg' /></Item>
    </BottomIcons>
  </Wrapper>
}

export default Sidebar;
