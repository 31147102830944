import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { itemsVar } from 'apollo/cache';

import { useReactiveVar  } from "@apollo/client";

type DefectProps = {
  confidence: number;
  active: boolean;
}
const getColor = (confidence: number) => {
  const TO = 215;
  const dec = 255 - Math.round((255 - TO) * confidence);
  const hex = dec.toString(16);
  return `#${hex}${hex}${hex}`;

}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.incidents.list.itemBg};
  margin-left: 14px;
  width: 30%;
  height: 100%;
  min-width: 320px;
  padding: 45px 36px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  // margin: 0 0 45px 0;
  margin-bottom: 45px;
`;
const Defect = styled.div<DefectProps>`
  background: ${({confidence, active}) => active ? '#00A3FF' : getColor(confidence)};
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({active}) => active ? '#ffffff' : '#343434'};
  padding: 12px 0;
  margin-bottom: 19px;
  width: 100%;
  cursor: pointer;
`;
const Details = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  padding: 24px 0;
`;
const Bold = styled.div`
  font-weight: bold;
`;
const SendAlert = styled.button`
  background: #FF0000;
  border-radius: 5px;
  width: 100%;
  padding: 12px 0;
  margin-top: 22px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;
  
  border: none;
  box-shadow: none;
`;
type defectType = {
  id: string,
  defect: string,
  name: string,
  source: string,
  severity: string,
  reactionPlan: string,
  confidence: number,
}

function RightBar({item}: any) {
  const { defects, id } = item;
  const [previous, setPrevious] = useState();
  const [activeDefect, setActiveDefect] = useState<defectType>();
  
  const items = useReactiveVar<any>(itemsVar);
  const handleClickDefect = (defect: defectType) => () => {
    setActiveDefect(defect);
    itemsVar(items.map(i => {
      return i.id === item.id 
        ? {...item, processed: true, verifiedDefect: defect.name } 
        : i
    }))
  };
  
  useEffect(() => {
    if(previous !== id) {
      setActiveDefect(undefined);
    }
    setPrevious(id === previous ? id : previous);
  }, [previous, setPrevious, id])


  return <Wrapper>
    <Title>Defect Type</Title>
    {defects.map((d: defectType) => <Defect
      onClick={handleClickDefect(d)}
      active={activeDefect ? d.id === activeDefect.id : false}
      confidence={d.confidence}
      key={d.id}
    >
      {d.name}
    </Defect>
    )}
    
    {activeDefect && <Details>
      {/* <Bold>Critical Incident</Bold>
      <Bold>{activeDefect.defect}</Bold> */}
      <div>Source: {activeDefect.source}</div>
      {activeDefect.reactionPlan.split(';').map((p, i) => <div key={i}>{p}</div>)}
      <SendAlert>Send Alert</SendAlert>
    </Details>
    }
  </Wrapper>
}

export default RightBar;