import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// import useFullscreen from "@rooks/use-fullscreen";

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Module from 'containers/Module';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;    
    font-family: Open Sans;
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
`
const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

function Root() {
//   const { isFullscreen, element, onChange } = useFullscreen();
  
//   onChange(() => {
//     console.log(isFullscreen, element);

//   });
  return <>
    <GlobalStyle />
    <Wrapper>
      {/* {!isFullscreen && <Header />} */}
      <Header />
      <Body>
        {/* {!isFullscreen && <Sidebar />} */}
        <Sidebar />
        <Module />
      </Body>
    </Wrapper>
  </>
}

export default Root;
