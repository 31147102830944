
export type incident = {
  "ID": number,
  "Critical": number,
  "Point of Control": string,
  // "Time Start": string,
  // "Time End": string,
  "Timeline Start": number | string,
  "Timeline End": number | string,
  "Date-Time Start": string,
  "Date-Time End": string,
  "Line": string,
  "Brand": string,
  "Images": string,
  "Defect1": number,
  "Defect2": number,
  "Defect3": number,
  "Defect4": number,
  "Defect5": number,
  "Defect6": number,
  "Verified Defect": string,
}

export const incidents: incident[] = [
  {
    "ID": 150,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:01:52",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-01-52.884_var_87.831399.jpeg",
    "Defect1": 0.804,
    "Defect2": 0.059,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.161,
    "Defect6": 0.35,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 151,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:02:00",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-02-00.884_var_91.650126.jpeg",
    "Defect1": 0.749,
    "Defect2": 0.167,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.21,
    "Defect6": 0.297,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 152,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:04:06",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-04-06.104_var_100.014563.jpeg",
    "Defect1": 0.256,
    "Defect2": 0.166,
    "Defect3": 0,
    "Defect4": 0.668,
    "Defect5": 0.274,
    "Defect6": 0.208,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 153,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:05:54",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-05-54.976_var_97.883123.jpeg",
    "Defect1": 0.896,
    "Defect2": 0.091,
    "Defect3": 0,
    "Defect4": 0.155,
    "Defect5": 0.312,
    "Defect6": 0.244,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 154,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:15:04",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-15-04.729_var_91.479267.jpeg",
    "Defect1": 0.059,
    "Defect2": 0.141,
    "Defect3": 0,
    "Defect4": 0.731,
    "Defect5": 0.258,
    "Defect6": 0.253,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 155,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:18:13",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-18-13.951_var_130.080315.jpeg",
    "Defect1": 0.749,
    "Defect2": 0.112,
    "Defect3": 0,
    "Defect4": 0.104,
    "Defect5": 0.433,
    "Defect6": 0.392,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 156,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:22:34",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-22-34.000_var_92.692143.jpeg",
    "Defect1": 0.935,
    "Defect2": 0.158,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.21,
    "Defect6": 0.297,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 157,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:27:35",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-27-35.442_var_89.755220.jpeg",
    "Defect1": 0.17,
    "Defect2": 0.12,
    "Defect3": 0,
    "Defect4": 0.896,
    "Defect5": 0.158,
    "Defect6": 0.031,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 158,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 06:48:40",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_06-48-40.516_var_119.124090.jpeg",
    "Defect1": 0.829,
    "Defect2": 0.228,
    "Defect3": 0,
    "Defect4": 0.208,
    "Defect5": 0.21,
    "Defect6": 0.167,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 159,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 07:22:00",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_07-22-00.909_var_69.668372.jpeg",
    "Defect1": 0.834,
    "Defect2": 0.17,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.21,
    "Defect6": 0.297,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 160,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 07:23:14",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NattyD 16oz",
    "Images": "frm_2020-10-13_07-23-14.477_var_118.447083.jpeg",
    "Defect1": 0.927,
    "Defect2": 0.12,
    "Defect3": 0,
    "Defect4": 0.121,
    "Defect5": 0.086,
    "Defect6": 0.194,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 91,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 10:13:20",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-13-20.269_var_103.480777.jpeg",
    "Defect1": 0.619,
    "Defect2": 0.238,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.161,
    "Defect6": 0.35,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 98,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 10:52:36",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-52-36.669_var_146.397906.jpeg",
    "Defect1": 0.804,
    "Defect2": 0.439,
    "Defect3": 0,
    "Defect4": 0.098,
    "Defect5": 0.21,
    "Defect6": 0.297,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 55,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:01:36",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-01-36.340_var_231.953438.jpeg",
    "Defect1": 0.256,
    "Defect2": 0.144,
    "Defect3": 0,
    "Defect4": 0.674,
    "Defect5": 0.424,
    "Defect6": 0.398,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 56,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:02:48",
    "Date-Time End": "13.10.20 11:02:50",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-02-48.951_var_178.597914 frm_2020-10-13_11-02-49.038_var_298.074313",
    "Defect1": 0.264,
    "Defect2": 0.354,
    "Defect3": 0,
    "Defect4": 0.709,
    "Defect5": 0.271,
    "Defect6": 0.141,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 57,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:03:35",
    "Date-Time End": "13.10.20 11:04:02",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-03-35.995_var_177.235041 frm_2020-10-13_11-03-36.081_var_78.413504 frm_2020-10-13_11-03-36.169_var_133.480149 frm_2020-10-13_11-03-36.256_var_257.308191 frm_2020-10-13_11-03-41.126_var_490.395225 frm_2020-10-13_11-04-01.126_var_86.382738",
    "Defect1": 0.215,
    "Defect2": 0.385,
    "Defect3": 0,
    "Defect4": 0.755,
    "Defect5": 0.304,
    "Defect6": 0.026,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 6,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:06:28",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-06-39.130_var_303.765519",
    "Defect1": 0.364,
    "Defect2": 0.325,
    "Defect3": 0,
    "Defect4": 0.099,
    "Defect5": 0.966,
    "Defect6": 0.127,
    "Verified Defect": "Defect5"
  },
  {
    "ID": 58,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:06:28",
    "Date-Time End": "13.10.20 11:07:00",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-06-28.869_var_178.702701 frm_2020-10-13_11-06-29.042_var_198.625116 frm_2020-10-13_11-06-29.129_var_298.108613 frm_2020-10-13_11-06-29.652_var_340.033591 frm_2020-10-13_11-06-52.260_var_397.664559 frm_2020-10-13_11-06-58.870_var_184.044318",
    "Defect1": 0.132,
    "Defect2": 0.274,
    "Defect3": 0,
    "Defect4": 0.818,
    "Defect5": 0.271,
    "Defect6": 0.314,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 60,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:11:13",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-11-13.483_var_127.271207.jpeg",
    "Defect1": 0.294,
    "Defect2": 0.283,
    "Defect3": 0,
    "Defect4": 0.787,
    "Defect5": 0.015,
    "Defect6": 0.093,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 8,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:15:32",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-15-32.881_var_119.701813.jpeg",
    "Defect1": 0.191,
    "Defect2": 0.158,
    "Defect3": 0,
    "Defect4": 0.303,
    "Defect5": 0.889,
    "Defect6": 0.486,
    "Verified Defect": "Defect5"
  },
  {
    "ID": 62,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:18:40",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-18-40.972_var_191.929556.jpeg",
    "Defect1": 0.272,
    "Defect2": 0.324,
    "Defect3": 0,
    "Defect4": 0.936,
    "Defect5": 0.329,
    "Defect6": 0.204,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 63,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:19:24",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-19-24.104_var_355.245287.jpeg",
    "Defect1": 0.177,
    "Defect2": 0.394,
    "Defect3": 0,
    "Defect4": 0.927,
    "Defect5": 0.084,
    "Defect6": 0.18,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 64,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:19:28",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-19-28.364_var_184.841245.jpeg",
    "Defect1": 0.456,
    "Defect2": 0.192,
    "Defect3": 0,
    "Defect4": 0.652,
    "Defect5": 0.174,
    "Defect6": 0.261,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 65,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:20:05",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-20-05.148_var_386.864073.jpeg",
    "Defect1": 0.245,
    "Defect2": 0.137,
    "Defect3": 0,
    "Defect4": 0.897,
    "Defect5": 0.267,
    "Defect6": 0.18,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 10,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:20:47",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-20-47.323_var_81.496446.jpeg",
    "Defect1": 0.073,
    "Defect2": 0.492,
    "Defect3": 0,
    "Defect4": 0.324,
    "Defect5": 0.886,
    "Defect6": 0.334,
    "Verified Defect": "Defect5"
  },
  {
    "ID": 25,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:21:00",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-21-04.193_var_323.514547",
    "Defect1": 0.342,
    "Defect2": 0.936,
    "Defect3": 0,
    "Defect4": 0.216,
    "Defect5": 0.335,
    "Defect6": 0.365,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 66,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:21:31",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-21-31.845_var_227.326040.jpeg",
    "Defect1": 0.137,
    "Defect2": 0.324,
    "Defect3": 0,
    "Defect4": 0.377,
    "Defect5": 0.371,
    "Defect6": 0.231,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 67,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:22:14",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-22-14.803_var_226.634244.jpeg",
    "Defect1": 0.201,
    "Defect2": 0.196,
    "Defect3": 0,
    "Defect4": 0.874,
    "Defect5": 0.315,
    "Defect6": 0.085,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 93,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:22:46",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-22-46.717_var_238.575190.jpeg",
    "Defect1": 0.611,
    "Defect2": 0.322,
    "Defect3": 0,
    "Defect4": 0.334,
    "Defect5": 0.376,
    "Defect6": 0.442,
    "Verified Defect": "Defect1"
  },
  {
    "ID": 68,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:22:53",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-22-53.760_var_314.397364.jpeg",
    "Defect1": 0.298,
    "Defect2": 0.261,
    "Defect3": 0,
    "Defect4": 0.63,
    "Defect5": 0.166,
    "Defect6": 0.172,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 69,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:22:53",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-22-53.847_var_161.665878.jpeg",
    "Defect1": 0.428,
    "Defect2": 0.16,
    "Defect3": 0,
    "Defect4": 0.835,
    "Defect5": 0.484,
    "Defect6": 0.163,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 27,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:23:26",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-23-26.544_var_335.412243.jpeg",
    "Defect1": 0.16,
    "Defect2": 0.736,
    "Defect3": 0,
    "Defect4": 0.155,
    "Defect5": 0.312,
    "Defect6": 0.244,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 70,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:24:40",
    "Date-Time End": "13.10.20 11:25:15",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-24-41.502_var_283.842376 frm_2020-10-13_11-24-50.198_var_386.308851 frm_2020-10-13_11-25-14.807_var_408.353936",
    "Defect1": 0.137,
    "Defect2": 0.496,
    "Defect3": 0,
    "Defect4": 0.745,
    "Defect5": 0.111,
    "Defect6": 0.142,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 28,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:25:40",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-25-50.460_var_300.746621",
    "Defect1": 0.171,
    "Defect2": 0.918,
    "Defect3": 0,
    "Defect4": 0.104,
    "Defect5": 0.433,
    "Defect6": 0.392,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 71,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:25:50",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-25-50.546_var_308.123821.jpeg",
    "Defect1": 0.353,
    "Defect2": 0.399,
    "Defect3": 0,
    "Defect4": 0.859,
    "Defect5": 0.202,
    "Defect6": 0.416,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 72,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:27:02",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-27-02.723_var_334.849721.jpeg",
    "Defect1": 0.268,
    "Defect2": 0.209,
    "Defect3": 0,
    "Defect4": 0.538,
    "Defect5": 0.245,
    "Defect6": 0.206,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 0,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:27:59",
    "Date-Time End": "13.10.20 11:30:35",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-27-59.155_var_21.673885 frm_2020-10-13_11-27-59.242_var_83.230117 frm_2020-10-13_11-27-59.329_var_58.845843 frm_2020-10-13_11-30-34.811_var_25.901045 frm_2020-10-13_11-30-34.899_var_177.796744 frm_2020-10-13_11-30-34.988_var_159.649959",
    "Defect1": 0.515,
    "Defect2": 0.332,
    "Defect3": 0,
    "Defect4": 0.238,
    "Defect5": 0.257,
    "Defect6": 0.642,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 73,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:31:27",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-31-27.684_var_88.398533.jpeg",
    "Defect1": 0.009,
    "Defect2": 0.264,
    "Defect3": 0,
    "Defect4": 0.753,
    "Defect5": 0.258,
    "Defect6": 0.119,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 74,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:31:40",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-31-40.293_var_240.038441.jpeg",
    "Defect1": 0.005,
    "Defect2": 0.091,
    "Defect3": 0,
    "Defect4": 0.736,
    "Defect5": 0.127,
    "Defect6": 0.074,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 75,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:32:44",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-32-44.121_var_408.604828.jpeg",
    "Defect1": 0.247,
    "Defect2": 0.298,
    "Defect3": 0,
    "Defect4": 0.781,
    "Defect5": 0.322,
    "Defect6": 0.391,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 76,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:34:12",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-34-12.992_var_232.803983.jpeg",
    "Defect1": 0.091,
    "Defect2": 0.147,
    "Defect3": 0,
    "Defect4": 0.593,
    "Defect5": 0.29,
    "Defect6": 0.012,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 77,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:35:04",
    "Date-Time End": "13.10.20 11:35:06",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-35-04.994_var_309.311508 frm_2020-10-13_11-35-05.081_var_416.829895",
    "Defect1": 0.198,
    "Defect2": 0.169,
    "Defect3": 0,
    "Defect4": 0.834,
    "Defect5": 0.261,
    "Defect6": 0.015,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 78,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:35:39",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-35-39.081_var_150.965666.jpeg",
    "Defect1": 0.239,
    "Defect2": 0.214,
    "Defect3": 0,
    "Defect4": 0.686,
    "Defect5": 0.207,
    "Defect6": 0.401,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 79,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:37:39",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-37-39.606_var_318.928090.jpeg",
    "Defect1": 0.382,
    "Defect2": 0.024,
    "Defect3": 0,
    "Defect4": 0.783,
    "Defect5": 0.416,
    "Defect6": 0.137,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 32,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:38:02",
    "Date-Time End": "13.10.20 11:41:00",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-38-17.867_var_242.362539 frm_2020-10-13_11-39-46.131_var_298.290128",
    "Defect1": 0.143,
    "Defect2": 0.806,
    "Defect3": 0,
    "Defect4": 0.732,
    "Defect5": 0.189,
    "Defect6": 0.364,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 80,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:39:45",
    "Date-Time End": "13.10.20 11:40:15",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-39-46.000_var_333.728231 frm_2020-10-13_11-40-14.044_var_305.456574",
    "Defect1": 0.311,
    "Defect2": 0.096,
    "Defect3": 0,
    "Defect4": 0.811,
    "Defect5": 0.389,
    "Defect6": 0.037,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 81,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:40:49",
    "Date-Time End": "13.10.20 11:41:20",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-40-49.262_var_174.669616 frm_2020-10-13_11-41-19.695_var_76.714001 frm_2020-10-13_11-41-19.782_var_60.932760 frm_2020-10-13_11-41-19.868_var_77.735520",
    "Defect1": 0.171,
    "Defect2": 0.206,
    "Defect3": 0,
    "Defect4": 0.745,
    "Defect5": 0.054,
    "Defect6": 0.076,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 83,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:45:48",
    "Date-Time End": "13.10.20 11:46:28",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-45-48.312_var_279.267314 frm_2020-10-13_11-46-27.705_var_237.507182 frm_2020-10-13_11-46-27.792_var_271.128502",
    "Defect1": 0.121,
    "Defect2": 0.228,
    "Defect3": 0,
    "Defect4": 0.522,
    "Defect5": 0.039,
    "Defect6": 0.432,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 39,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:49:28",
    "Date-Time End": "13.10.20 11:49:40",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-49-28.665_var_191.118503 frm_2020-10-13_11-49-28.752_var_375.159050 frm_2020-10-13_11-49-37.187_var_443.406193",
    "Defect1": 0.304,
    "Defect2": 0.73,
    "Defect3": 0,
    "Defect4": 0.208,
    "Defect5": 0.21,
    "Defect6": 0.167,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 84,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:49:44",
    "Date-Time End": "13.10.20 11:50:25",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-49-45.361_var_379.663082 frm_2020-10-13_11-49-45.449_var_229.640974 frm_2020-10-13_11-49-56.492_var_234.910123 frm_2020-10-13_11-49-56.579_var_216.071841 frm_2020-10-13_11-50-24.319_var_250.097760",
    "Defect1": 0.184,
    "Defect2": 0.037,
    "Defect3": 0,
    "Defect4": 0.829,
    "Defect5": 0.16,
    "Defect6": 0.382,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 43,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:51:22",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-51-22.842_var_303.419177.jpeg",
    "Defect1": 0.224,
    "Defect2": 0.546,
    "Defect3": 0,
    "Defect4": 0.241,
    "Defect5": 0.168,
    "Defect6": 0.362,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 85,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:51:28",
    "Date-Time End": "13.10.20 11:51:29",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-51-28.059_var_414.709200 frm_2020-10-13_11-51-28.146_var_304.792618",
    "Defect1": 0.166,
    "Defect2": 0.473,
    "Defect3": 0,
    "Defect4": 0.648,
    "Defect5": 0.308,
    "Defect6": 0.343,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 86,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:52:02",
    "Date-Time End": "13.10.20 11:52:26",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-52-02.321_var_230.783655 frm_2020-10-13_11-52-07.539_var_377.382649 frm_2020-10-13_11-52-07.625_var_417.647458 frm_2020-10-13_11-52-25.974_var_298.477807",
    "Defect1": 0.267,
    "Defect2": 0.362,
    "Defect3": 0,
    "Defect4": 0.736,
    "Defect5": 0.228,
    "Defect6": 0.246,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 46,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:53:30",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-53-30.757_var_278.481017.jpeg",
    "Defect1": 0.084,
    "Defect2": 0.896,
    "Defect3": 0,
    "Defect4": 0.284,
    "Defect5": 0.19,
    "Defect6": 0.293,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 87,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:54:27",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-54-27.019_var_130.286447.jpeg",
    "Defect1": 0.385,
    "Defect2": 0.493,
    "Defect3": 0,
    "Defect4": 0.935,
    "Defect5": 0.411,
    "Defect6": 0.607,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 88,
    "Critical": 1,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:55:23",
    "Date-Time End": "13.10.20 11:55:50",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-55-23.455_var_194.191530 frm_2020-10-13_11-55-32.239_var_191.797494 frm_2020-10-13_11-55-32.325_var_419.707884 frm_2020-10-13_11-55-33.891_var_266.399322 frm_2020-10-13_11-55-41.630_var_262.068938 frm_2020-10-13_11-55-41.717_var_252.166378 frm_2020-10-13_11-55-43.369_var_378.857193 frm_2020-10-13_11-55-43.456_var_263.441703 frm_2020-10-13_11-55-43.543_var_127.238718 frm_2020-10-13_11-55-46.848_var_155.396880 frm_2020-10-13_11-55-49.022_var_281.779420",
    "Defect1": 0.256,
    "Defect2": 0.038,
    "Defect3": 0,
    "Defect4": 0.601,
    "Defect5": 0.098,
    "Defect6": 0.325,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 49,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:56:01",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-56-02.759_var_202.630309",
    "Defect1": 0.112,
    "Defect2": 0.78,
    "Defect3": 0,
    "Defect4": 0.121,
    "Defect5": 0.086,
    "Defect6": 0.194,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 2,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:56:15",
    "Date-Time End": "13.10.20 11:57:28",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-56-15.019_var_72.983662 frm_2020-10-13_11-56-15.106_var_112.198770 frm_2020-10-13_11-56-15.194_var_291.945042 frm_2020-10-13_11-56-15.281_var_192.313964 frm_2020-10-13_11-56-15.367_var_92.094463 frm_2020-10-13_11-56-15.454_var_58.716765 frm_2020-10-13_11-56-15.541_var_68.258026 frm_2020-10-13_11-56-15.628_var_158.576162 frm_2020-10-13_11-56-15.715_var_162.121100 frm_2020-10-13_11-56-15.802_var_111.789649 frm_2020-10-13_11-56-15.889_var_130.523979 frm_2020-10-13_11-56-15.976_var_120.215903 frm_2020-10-13_11-56-16.062_var_40.723682 frm_2020-10-13_11-56-16.149_var_56.821236 frm_2020-10-13_11-56-16.237_var_196.907109 frm_2020-10-13_11-56-16.323_var_94.366639 frm_2020-10-13_11-56-16.410_var_34.543512 frm_2020-10-13_11-56-16.497_var_21.459600 frm_2020-10-13_11-56-16.584_var_186.799062 frm_2020-10-13_11-56-16.671_var_276.652818 frm_2020-10-13_11-56-16.758_var_180.248246 frm_2020-10-13_11-56-16.845_var_28.117260 frm_2020-10-13_11-57-27.977_var_32.886215",
    "Defect1": 0.232,
    "Defect2": 0.037,
    "Defect3": 0,
    "Defect4": 0.177,
    "Defect5": 0.083,
    "Defect6": 0.873,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 89,
    "Critical": 1,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 11:57:50",
    "Date-Time End": "13.10.20 11:58:57",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-57-50.155_var_246.784474 frm_2020-10-13_11-58-10.677_var_193.122069 frm_2020-10-13_11-58-10.764_var_90.614597 frm_2020-10-13_11-58-32.329_var_320.271152 frm_2020-10-13_11-58-56.678_var_125.499958",
    "Defect1": 0.332,
    "Defect2": 0.166,
    "Defect3": 0,
    "Defect4": 0.597,
    "Defect5": 0.158,
    "Defect6": 0.031,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 100,
    "Critical": 1,
    "Point of Control": "Filler",
    "Timeline Start": 0.63,
    "Timeline End": 0.64,
    "Date-Time Start": "13.10.20 12:05:50",
    "Date-Time End": "13.10.20 12:13:30",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-09-28.175_var_179.043992.jpeg frm_2020-10-13_11-09-28.262_var_190.156563.jpeg frm_2020-10-13_11-09-28.350_var_178.151747.jpeg",
    "Defect1": 0.112,
    "Defect2": 0.2,
    "Defect3": 0,
    "Defect4": 0.45,
    "Defect5": 0.32,
    "Defect6": 0.97,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 121,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 12:29:27",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-29-27.847_var_317.015954.jpeg",
    "Defect1": 0.383,
    "Defect2": 0.167,
    "Defect3": 0,
    "Defect4": 0.608,
    "Defect5": 0.236,
    "Defect6": 0.231,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 108,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:00:37",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-00-37.633_var_165.332595.jpeg",
    "Defect1": 0.548,
    "Defect2": 0.731,
    "Defect3": 0,
    "Defect4": 0.046,
    "Defect5": 0.11,
    "Defect6": 0.282,
    "Verified Defect": "Defect2"
  },
  {
    "ID": 117,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:00:58",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-00-58.155_var_212.822109.jpeg",
    "Defect1": 0.141,
    "Defect2": 0.059,
    "Defect3": 0,
    "Defect4": 0.677,
    "Defect5": 0.344,
    "Defect6": 0.405,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 118,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:03:22",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-03-22.245_var_232.120793.jpeg",
    "Defect1": 0.091,
    "Defect2": 0.405,
    "Defect3": 0,
    "Defect4": 0.424,
    "Defect5": 0.15,
    "Defect6": 0.185,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 120,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:18:17",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-18-17.832_var_162.043948.jpeg",
    "Defect1": 0.17,
    "Defect2": 0.367,
    "Defect3": 0,
    "Defect4": 0.485,
    "Defect5": 0.298,
    "Defect6": 0.269,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 122,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:37:42",
    "Date-Time End": "13.10.20 13:37:54",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-37-43.859_var_380.331705 frm_2020-10-13_13-37-53.511_var_165.245666",
    "Defect1": 0.554,
    "Defect2": 0.515,
    "Defect3": 0,
    "Defect4": 0.749,
    "Defect5": 0.253,
    "Defect6": 0.138,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 123,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:43:13",
    "Date-Time End": "13.10.20 13:44:34",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-43-14.388_var_315.808314 frm_2020-10-13_13-44-33.520_var_187.137767",
    "Defect1": 0.668,
    "Defect2": 0.12,
    "Defect3": 0,
    "Defect4": 0.637,
    "Defect5": 0.274,
    "Defect6": 0.258,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 124,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "#VALUE!",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 13:47:31",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_13-47-31.524_var_218.608739.jpeg",
    "Defect1": 0.545,
    "Defect2": 0.125,
    "Defect3": 0,
    "Defect4": 0.713,
    "Defect5": 0.208,
    "Defect6": 0.519,
    "Verified Defect": "Defect4"
  },
  {
    "ID": 201,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 20:26:33",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NaturalIce 16oz",
    "Images": "frm_2020-10-12_20-26-33.210_var_324.067713.jpeg",
    "Defect1": 0.241,
    "Defect2": 0.12,
    "Defect3": 0,
    "Defect4": 0.013,
    "Defect5": 0.291,
    "Defect6": 0.832,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 202,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 20:38:01",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NaturalIce 16oz",
    "Images": "frm_2020-10-12_20-38-01.051_var_209.707378.jpeg",
    "Defect1": 0.033,
    "Defect2": 0.046,
    "Defect3": 0,
    "Defect4": 0.234,
    "Defect5": 0.247,
    "Defect6": 0.732,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 203,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 20:42:04",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NaturalIce 16oz",
    "Images": "frm_2020-10-12_20-42-04.535_var_176.195742.jpeg",
    "Defect1": 0.052,
    "Defect2": 0.292,
    "Defect3": 0,
    "Defect4": 0.258,
    "Defect5": 0.159,
    "Defect6": 0.826,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 204,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 20:43:40",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NaturalIce 16oz",
    "Images": "frm_2020-10-12_20-43-40.190_var_145.195375.jpeg",
    "Defect1": 0.081,
    "Defect2": 0.225,
    "Defect3": 0,
    "Defect4": 0.271,
    "Defect5": 0.084,
    "Defect6": 0.977,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 205,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 20:45:54",
    "Date-Time End": "",
    "Line": "CL61",
    "Brand": "NaturalIce 16oz",
    "Images": "frm_2020-10-12_20-45-54.976_var_217.004288.jpeg",
    "Defect1": 0.252,
    "Defect2": 0.103,
    "Defect3": 0,
    "Defect4": 0.234,
    "Defect5": 0.197,
    "Defect6": 0.821,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 300,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:00:11",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-00-11.640_var_47.829104",
    "Defect1": 0.079,
    "Defect2": 0.272,
    "Defect3": 0.249,
    "Defect4": 0.198,
    "Defect5": 0.127,
    "Defect6": 0.292,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 301,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:01:03",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-01-03.557_var_138.278207",
    "Defect1": 0.141,
    "Defect2": 0.051,
    "Defect3": 0.118,
    "Defect4": 0.286,
    "Defect5": 0.006,
    "Defect6": 0.037,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 302,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:03:28",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-03-28.256_var_243.439198",
    "Defect1": 0.293,
    "Defect2": 0.15,
    "Defect3": 0.069,
    "Defect4": 0.281,
    "Defect5": 0.245,
    "Defect6": 0.276,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 303,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:04:05",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-04-05.300_var_117.748535",
    "Defect1": 0.112,
    "Defect2": 0.28,
    "Defect3": 0.102,
    "Defect4": 0.192,
    "Defect5": 0.284,
    "Defect6": 0.299,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 304,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:05:18",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-05-18.345_var_350.103396",
    "Defect1": 0.053,
    "Defect2": 0.102,
    "Defect3": 0.066,
    "Defect4": 0.232,
    "Defect5": 0.101,
    "Defect6": 0.277,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 305,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:07:02",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-07-02.782_var_314.773641",
    "Defect1": 0.241,
    "Defect2": 0.237,
    "Defect3": 0.085,
    "Defect4": 0.244,
    "Defect5": 0.138,
    "Defect6": 0.096,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 306,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:07:22",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-07-22.261_var_284.219085",
    "Defect1": 0.205,
    "Defect2": 0.284,
    "Defect3": 0.057,
    "Defect4": 0.011,
    "Defect5": 0.203,
    "Defect6": 0.217,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 307,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:07:48",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-07-48.610_var_270.133750",
    "Defect1": 0.254,
    "Defect2": 0.193,
    "Defect3": 0.07,
    "Defect4": 0.16,
    "Defect5": 0.08,
    "Defect6": 0.102,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 308,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:10:16",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-10-16.265_var_235.227212",
    "Defect1": 0.042,
    "Defect2": 0.023,
    "Defect3": 0.078,
    "Defect4": 0.202,
    "Defect5": 0.048,
    "Defect6": 0.146,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 309,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:10:18",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-10-18.700_var_315.319585",
    "Defect1": 0.185,
    "Defect2": 0.203,
    "Defect3": 0.226,
    "Defect4": 0.143,
    "Defect5": 0.199,
    "Defect6": 0.102,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 310,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:12:02",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-12-02.528_var_235.023347",
    "Defect1": 0.265,
    "Defect2": 0.03,
    "Defect3": 0.233,
    "Defect4": 0.125,
    "Defect5": 0.126,
    "Defect6": 0.145,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 311,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:12:59",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-12-59.312_var_221.641254",
    "Defect1": 0.221,
    "Defect2": 0.077,
    "Defect3": 0.21,
    "Defect4": 0.233,
    "Defect5": 0.217,
    "Defect6": 0.275,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 312,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:13:54",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-13-54.792_var_242.635710",
    "Defect1": 0.285,
    "Defect2": 0.285,
    "Defect3": 0.086,
    "Defect4": 0.3,
    "Defect5": 0.123,
    "Defect6": 0.151,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 313,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:14:25",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-14-25.922_var_76.150006",
    "Defect1": 0.186,
    "Defect2": 0.253,
    "Defect3": 0.027,
    "Defect4": 0.023,
    "Defect5": 0.299,
    "Defect6": 0.114,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 314,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:16:28",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-16-28.795_var_389.359579",
    "Defect1": 0.127,
    "Defect2": 0.002,
    "Defect3": 0.128,
    "Defect4": 0.235,
    "Defect5": 0.043,
    "Defect6": 0.074,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 315,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:16:39",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-16-39.404_var_220.648864",
    "Defect1": 0.02,
    "Defect2": 0.081,
    "Defect3": 0.033,
    "Defect4": 0.249,
    "Defect5": 0.041,
    "Defect6": 0.225,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 316,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:17:05",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-17-05.317_var_95.502895",
    "Defect1": 0.011,
    "Defect2": 0.271,
    "Defect3": 0.247,
    "Defect4": 0.254,
    "Defect5": 0.112,
    "Defect6": 0.199,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 317,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:19:31",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-19-31.756_var_261.455612",
    "Defect1": 0.221,
    "Defect2": 0.208,
    "Defect3": 0.298,
    "Defect4": 0.008,
    "Defect5": 0.114,
    "Defect6": 0.108,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 318,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:20:36",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-20-36.279_var_206.326951",
    "Defect1": 0.22,
    "Defect2": 0.194,
    "Defect3": 0.065,
    "Defect4": 0.03,
    "Defect5": 0.043,
    "Defect6": 0.296,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 319,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:21:11",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-21-11.758_var_282.744289",
    "Defect1": 0.259,
    "Defect2": 0.219,
    "Defect3": 0.125,
    "Defect4": 0.158,
    "Defect5": 0.049,
    "Defect6": 0.078,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 320,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:24:12",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-24-12.632_var_291.243417",
    "Defect1": 0.229,
    "Defect2": 0.238,
    "Defect3": 0.253,
    "Defect4": 0.195,
    "Defect5": 0.055,
    "Defect6": 0.254,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 321,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:25:14",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-25-14.807_var_408.353936",
    "Defect1": 0.237,
    "Defect2": 0.266,
    "Defect3": 0.288,
    "Defect4": 0.02,
    "Defect5": 0.291,
    "Defect6": 0.284,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 322,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:30:43",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-30-43.249_var_373.938715",
    "Defect1": 0.276,
    "Defect2": 0.165,
    "Defect3": 0.132,
    "Defect4": 0.008,
    "Defect5": 0.294,
    "Defect6": 0.061,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 323,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:33:45",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-33-45.165_var_227.644409",
    "Defect1": 0.278,
    "Defect2": 0.114,
    "Defect3": 0.073,
    "Defect4": 0.204,
    "Defect5": 0.268,
    "Defect6": 0.165,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 324,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:34:58",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-34-58.384_var_347.755245",
    "Defect1": 0.145,
    "Defect2": 0.178,
    "Defect3": 0.066,
    "Defect4": 0.052,
    "Defect5": 0.185,
    "Defect6": 0.033,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 325,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:37:01",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-37-01.866_var_268.661866",
    "Defect1": 0.01,
    "Defect2": 0.195,
    "Defect3": 0.133,
    "Defect4": 0.26,
    "Defect5": 0.14,
    "Defect6": 0.269,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 326,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:37:19",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-37-19.866_var_344.691152",
    "Defect1": 0.221,
    "Defect2": 0.114,
    "Defect3": 0.219,
    "Defect4": 0.018,
    "Defect5": 0.157,
    "Defect6": 0.143,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 327,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:38:18",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-38-18.824_var_132.821408",
    "Defect1": 0.023,
    "Defect2": 0.167,
    "Defect3": 0.06,
    "Defect4": 0.048,
    "Defect5": 0.276,
    "Defect6": 0.01,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 328,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:38:20",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-38-20.476_var_166.988473",
    "Defect1": 0.024,
    "Defect2": 0.049,
    "Defect3": 0.186,
    "Defect4": 0.288,
    "Defect5": 0.205,
    "Defect6": 0.275,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 329,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:39:23",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-39-23.521_var_384.834238",
    "Defect1": 0.091,
    "Defect2": 0.061,
    "Defect3": 0.051,
    "Defect4": 0.021,
    "Defect5": 0.065,
    "Defect6": 0.223,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 330,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:40:15",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-40-15.870_var_99.000132",
    "Defect1": 0.048,
    "Defect2": 0.218,
    "Defect3": 0.045,
    "Defect4": 0.267,
    "Defect5": 0.048,
    "Defect6": 0.165,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 331,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:41:15",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-41-15.610_var_141.737405",
    "Defect1": 0.211,
    "Defect2": 0.187,
    "Defect3": 0.272,
    "Defect4": 0.122,
    "Defect5": 0.018,
    "Defect6": 0.163,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 332,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:41:17",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-41-17.263_var_276.046639",
    "Defect1": 0.26,
    "Defect2": 0.282,
    "Defect3": 0.154,
    "Defect4": 0.222,
    "Defect5": 0.09,
    "Defect6": 0.097,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 333,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:41:23",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-41-23.608_var_164.446179",
    "Defect1": 0.219,
    "Defect2": 0.076,
    "Defect3": 0.099,
    "Defect4": 0.262,
    "Defect5": 0.131,
    "Defect6": 0.058,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 334,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:41:25",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-41-25.521_var_120.788948",
    "Defect1": 0.192,
    "Defect2": 0.187,
    "Defect3": 0.023,
    "Defect4": 0.185,
    "Defect5": 0.171,
    "Defect6": 0.258,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 335,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:41:27",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-41-27.695_var_190.056114",
    "Defect1": 0.211,
    "Defect2": 0.025,
    "Defect3": 0.16,
    "Defect4": 0.21,
    "Defect5": 0.071,
    "Defect6": 0.124,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 336,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:45:16",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-45-16.834_var_283.170823",
    "Defect1": 0.008,
    "Defect2": 0.062,
    "Defect3": 0.037,
    "Defect4": 0.217,
    "Defect5": 0.174,
    "Defect6": 0.044,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 337,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:46:51",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-46-51.183_var_176.765756",
    "Defect1": 0.086,
    "Defect2": 0.229,
    "Defect3": 0.15,
    "Defect4": 0.173,
    "Defect5": 0.052,
    "Defect6": 0.24,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 338,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:47:41",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-47-41.011_var_360.798580",
    "Defect1": 0.106,
    "Defect2": 0.156,
    "Defect3": 0.227,
    "Defect4": 0.249,
    "Defect5": 0.218,
    "Defect6": 0.198,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 339,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:49:38",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-49-38.578_var_267.185725",
    "Defect1": 0.069,
    "Defect2": 0.183,
    "Defect3": 0.281,
    "Defect4": 0.196,
    "Defect5": 0.069,
    "Defect6": 0.057,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 340,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:49:56",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-49-56.579_var_216.071841",
    "Defect1": 0.005,
    "Defect2": 0.248,
    "Defect3": 0.133,
    "Defect4": 0.222,
    "Defect5": 0.163,
    "Defect6": 0.112,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 341,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:50:32",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-50-32.058_var_139.386646",
    "Defect1": 0.181,
    "Defect2": 0.133,
    "Defect3": 0.158,
    "Defect4": 0.215,
    "Defect5": 0.257,
    "Defect6": 0.22,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 342,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:50:35",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-50-35.449_var_282.183122",
    "Defect1": 0.279,
    "Defect2": 0.242,
    "Defect3": 0.254,
    "Defect4": 0.209,
    "Defect5": 0.021,
    "Defect6": 0.031,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 343,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:50:42",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-50-42.841_var_129.285517",
    "Defect1": 0.237,
    "Defect2": 0.2,
    "Defect3": 0.188,
    "Defect4": 0.207,
    "Defect5": 0.088,
    "Defect6": 0.289,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 344,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:52:40",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-52-40.148_var_294.731341",
    "Defect1": 0.181,
    "Defect2": 0.168,
    "Defect3": 0.192,
    "Defect4": 0.197,
    "Defect5": 0.083,
    "Defect6": 0.093,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 345,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:53:25",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-53-25.540_var_203.170436",
    "Defect1": 0.242,
    "Defect2": 0.191,
    "Defect3": 0.225,
    "Defect4": 0.202,
    "Defect5": 0.162,
    "Defect6": 0.212,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 346,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:54:01",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-54-01.106_var_390.049187",
    "Defect1": 0.152,
    "Defect2": 0.011,
    "Defect3": 0.108,
    "Defect4": 0.173,
    "Defect5": 0.299,
    "Defect6": 0.274,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 347,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:54:37",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-54-37.368_var_317.685021",
    "Defect1": 0.172,
    "Defect2": 0.129,
    "Defect3": 0.047,
    "Defect4": 0.169,
    "Defect5": 0.264,
    "Defect6": 0.15,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 348,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:56:01",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-56-01.369_var_440.333274",
    "Defect1": 0.133,
    "Defect2": 0.107,
    "Defect3": 0.153,
    "Defect4": 0.176,
    "Defect5": 0.255,
    "Defect6": 0.084,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 349,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:56:07",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-56-07.975_var_127.316026",
    "Defect1": 0.118,
    "Defect2": 0.183,
    "Defect3": 0.072,
    "Defect4": 0.266,
    "Defect5": 0.06,
    "Defect6": 0.124,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 350,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:56:10",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-56-10.324_var_186.814151",
    "Defect1": 0.038,
    "Defect2": 0.138,
    "Defect3": 0.244,
    "Defect4": 0.23,
    "Defect5": 0.023,
    "Defect6": 0.169,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 351,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:57:43",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-57-43.981_var_167.560833",
    "Defect1": 0.038,
    "Defect2": 0.063,
    "Defect3": 0.21,
    "Defect4": 0.038,
    "Defect5": 0.271,
    "Defect6": 0.184,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 352,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:57:50",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-57-50.068_var_270.720002",
    "Defect1": 0.108,
    "Defect2": 0.092,
    "Defect3": 0.228,
    "Defect4": 0.208,
    "Defect5": 0.16,
    "Defect6": 0.244,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 353,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:58:10",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-58-10.155_var_273.710649",
    "Defect1": 0.084,
    "Defect2": 0.117,
    "Defect3": 0.292,
    "Defect4": 0.02,
    "Defect5": 0.207,
    "Defect6": 0.008,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 354,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:58:12",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-58-12.677_var_237.194225",
    "Defect1": 0.237,
    "Defect2": 0.169,
    "Defect3": 0.138,
    "Defect4": 0.126,
    "Defect5": 0.212,
    "Defect6": 0.026,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 355,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:59:36",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-59-36.331_var_333.334089",
    "Defect1": 0.214,
    "Defect2": 0.171,
    "Defect3": 0.262,
    "Defect4": 0.272,
    "Defect5": 0.103,
    "Defect6": 0.149,
    "Verified Defect": "Defect6"
  },
  {
    "ID": 356,
    "Critical": 0,
    "Point of Control": "Filler",
    "Timeline Start": "",
    "Timeline End": "",
    "Date-Time Start": "13.10.20 23:59:37",
    "Date-Time End": "",
    "Line": "CL62",
    "Brand": "Budweiser 16oz",
    "Images": "frm_2020-10-13_11-59-37.027_var_341.314161",
    "Defect1": 0.238,
    "Defect2": 0.013,
    "Defect3": 0.092,
    "Defect4": 0.224,
    "Defect5": 0.027,
    "Defect6": 0.194,
    "Verified Defect": "Defect6"
  }
]