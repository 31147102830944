import React from 'react';
import styled from 'styled-components';
import { useRouteMatch } from "react-router-dom";

import { Scrollbars } from 'react-custom-scrollbars';
import useDimensions from 'react-use-dimensions';

import { useReactiveVar  } from "@apollo/client";
import { itemsVar } from 'apollo/cache';

import Item from 'modules/Incidents/ListItem';
import Details from 'modules/Incidents/Details';
import RightBar from 'modules/Incidents/RightBar';

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  height: 100%;
  background: ${({theme}) => theme.incidents.list.bg};
  margin-top: ${({theme}) => theme.incidents.header.height};
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.incidents.list.bg};
  min-width: 360px;
`;

function List() {
  const [ref, {height}] = useDimensions();
  const match: any = useRouteMatch();
  const items = useReactiveVar<any>(itemsVar);
  
  const activeId = match.params.id ? match.params.id : items[0].id;
  const item = items.find((i: any) => i.id === activeId);  

  return <Wrapper ref={ref}>
    <Items>
    <Scrollbars 
      style={{height: height - 80}}
    >
      {items.map((i: any) => <Item key={i.id} item={{...i, active: activeId === i.id}} />)}
    </Scrollbars>
    </Items>
    <Details item={item} />
    <RightBar item={item} activeId={activeId} />
  </Wrapper>
}

export default List;