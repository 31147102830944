import React from 'react';
import styled from 'styled-components';
import VideoLabel from 'modules/ShiftDashboard/VideoLabel';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;
const Half = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
`;
const HalfV = styled(Half)`
  height: 50%;
`;
const Video = styled.img`
  width: 100%;
  height: auto;
  padding: 8px;
  background: #343434;
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #343434;
`;
const Image = styled.img`
  width: 100%;
`;

function Videos() {
  return <Wrapper>
    <Half>
      <Video src="/video/Budweiser_gif_3.gif" />
    </Half>
    <Half>
      <HalfV>
        <VideoLabel label="Lateside">
          <Video src="/video/Budweiser_gif_new.gif" />
        </VideoLabel>
      </HalfV>
      <HalfV>
        <VideoLabel label="Closer Top">
          <Video src="/video/Conveyor_new4_2.gif" />
        </VideoLabel>
      </HalfV>
      <HalfV>
        <VideoLabel label="Palletizer">
          <Video src="/video/pallets_cam_gif.gif" />
        </VideoLabel>
      </HalfV>
      <HalfV>
        <ImageWrapper>
          <Image src="/images/AB_InBev_logo.png" />
        </ImageWrapper>
      </HalfV>
    </Half>
  </Wrapper>
}

export default Videos;