import React,  { useEffect, useState }  from 'react';
import styled from 'styled-components';

import LeftBar from 'modules/ShiftDashboard/LeftBar';
import Content from 'modules/ShiftDashboard/Content';
import Timeline from 'modules/ShiftDashboard/Timeline';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  font-weight: 500;
  background: #131313;
  width: 100%;
  height: 100%;
  color: #D6D6D6;
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 64px;
  width: calc(100% - 64px);
  height: 32px;
  display: flex;
  justify-content: space-between;
  background: #505050;
`;
const FooterLeft = styled.img`
  
`;
const FooterRight = styled.img`

`;

function ShiftDashboard({match}: any) {
  return <Wrapper>
    <LeftBar />
    <Content /> 

    <Timeline />    
    <Footer><FooterLeft src="/png/timeline/footer_left2.png" /><FooterRight src="/png/timeline/footer_right.png"  /></Footer>   
  </Wrapper>
  
}

export default ShiftDashboard;
