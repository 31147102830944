import React from 'react';
import styled from 'styled-components';

import { itemsVar } from 'apollo/cache';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({theme}) => theme.incidents.header.height};
  background: ${({theme}) => theme.incidents.header.bg};
  color: ${({theme}) => theme.incidents.header.color};
  border-bottom: 2px solid #C5C5C5;
  
  position: fixed;
  z-index: 100;
  width: 100%;
`;


// font-size: ${({theme}) => theme.incidents.header.fontSize};
// background: ${({theme}) => theme.incidents.header.itemBg};
// color: ${({theme}) => theme.incidents.header.itemColor};
const Item = styled.span`
  margin-left: 29px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

//
// background: ${({theme}) => theme.incidents.header.itemCircleBg};
// color: ${({theme}) => theme.incidents.header.itemCircleColor};
const Circle = styled.div`
  display: inline-block;
  color: #00A3FF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;


function Header() {
  const items = itemsVar();
  const values = [
    { text: 'New', number: items.length - 1},
    { text: 'Current shift', number: '15'},
    { text: 'Last 24h', number: '64' },
    { text: 'Archive', number: null },
  ]
  return <Wrapper>
    {values.map((i, ind) => <Item key={ind}>{i.text}{i.number ? <Circle>{i.number}</Circle> : null}</Item>)}   
  </Wrapper>
}

export default Header;