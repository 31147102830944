import React from 'react';
import styled, { css} from 'styled-components';
import { Link } from 'react-router-dom';

import RoundIcon from 'modules/Incidents/RoundIcon';

import ShowDefect from 'modules/Incidents/ShowDefect';

type ItemProps = {
  active?: boolean;
}
const Item = styled.div<ItemProps>`
  position: relative;
  font-size: ${({theme}) => theme.incidents.list.fontSize};
  ${({theme, active=false}) => active 
    ? css`background: ${theme.incidents.list.itemBgActive};`
    : css`
      background: ${theme.incidents.list.itemBg};
      &:hover {
        background: ${theme.incidents.list.itemBgHover};
      }
    `
  };
  color: ${({theme}) => theme.incidents.list.itemColor};
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 12px 0 12px 0;
  border-bottom: 2px solid #C5C5C5;
`;
type CircleProps = {
  processed: boolean,
}
const Circle = styled.div<CircleProps>`
  display: inline-block;
  background: ${({theme, processed}) => processed ? '#c5c5c5' : theme.incidents.list.itemCircleBg};
  color: ${({theme}) => theme.incidents.list.itemCircleColor};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`
export const Product = styled.div`
  color: #343434;
  font-weight: bold;
`;
export const ControlPoint = styled.div`
  color: #00A3FF;
  font-weight: bold;
`;
type DefectProps = {
  black: boolean,
}
export const DefectType = styled.div<DefectProps>`
  font-weight: ${({black}) => black ? 'bold' : '500'};
  color: ${({black}) => black ? '#000' : 'rgba(0, 0, 0, 0.58)'};
`;

const Time = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  position: absolute;
  right: 36px;
  width: 100px;
  text-align: right;
`;
const Badge = styled.div`
  position: absolute;
  right: 0;
  background: #FF3535;
  border-radius: 10px 0px 0px 10px;
  color: #fff;
  font-weight: bold;  
  font-size: 16px;
  line-height: 20px;
  padding: 6px 15px;
  width: 120px;
  box-sizing: border-box;
  text-align: center;
  margin-left: 24px;
`;

// type ListProps = {
//   active: boolean,
//   id: string,
//   brand: string, 
//   line: string, 
//   controlPoint: string, 
//   defectType: string, 
//   hotline?: string,
//   images: [string],
// }

function ListItem({item}:any) {
  const {id, critical, brand, line, timeStart, showDefects, controlPoint, processed, active, images} = item;
  const img = Array.isArray(images) ? images[0] : '';

  return <Link to={id}>
    <Item active={active}>
      <Circle processed={processed} />
      <RoundIcon src={img}/>
      <Text>
        <Product>{brand}</Product>
        <ControlPoint>{line} {controlPoint}</ControlPoint>
        <DefectType black={processed}><ShowDefect item={item} /></DefectType>
      </Text>
      {/* {critical ? <Badge>Today {timeStart}</Badge> : <Time>Today {timeStart}</Time>} */}
      <Time>Today {timeStart}</Time>
    </Item>
  </Link>
}

export default ListItem;