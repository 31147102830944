import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider,  } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import './App.css';
import client from 'apollo';
import Root from 'containers/Root';
import staticTheme from 'theme/theme';

function App() {
  // NB: add getting theme from google tables
  const themeObject = staticTheme;
  return (
    <Router>
      <ApolloProvider client={client}>
        <ThemeProvider theme={themeObject}>
            <Root />
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
