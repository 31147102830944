const theme = {
  main: '#fff',
  header: {
    bg: '#1B2635',
    color: '#fff',
    height: '64px',
  },
  sidebar: {
    bg: '#1B2635',
    color: '#fff',
    itemHoverBg: '#233044',
    width: '64px',
  },
  module: {
    bg: '#fefefe',
    color: '#000',
    width: '100%',
    height: '100%',
  },
  incidents: {
    header: {
      height: '48px',
      fontSize: '16px',
      bg: '#EDF1F6',
      color: '#000000',
      itemCircleBg: '#00A3FF',
      itemCircleColor: '#FFFFFF',
    },
    list: {
      bg: '#DCDEE0',
      itemBg: '#FFFFFF',
      itemBgActive: 'transparent',
      itemBgHover: 'rgba(255, 255, 255, 0.6)',
      itemColor: '#000',
      itemCircleBg: '#FF0000',
      itemCircleColor: '#FFFFFF',
    }
  },
  shiftDashboard: {
    header: {
      heigth: '48px',
    },
  },
  paddings: {
    block: '12px 16px',
    blockSides: '16px',
    blockVert: '12px',
  },
}

export default theme;