import {incidents, incident} from './incidents';

type defect = {
  id: string,
  defect: string,
  name: string,
  source: string,
  severity: string,
  reactionPlan: string,
  color: string,
}

export const defects: defect[] = [

  {
    id: 'Defect1',
    defect: 'zdent',
    name: 'Z-Dent / Wrinkle',
    source: 'Filler',
    severity: 'Critical',
    reactionPlan: `1. Stop the line
    2. Block the material
    3. Correct`,
    color: '#F4AC45',
  },
  {
    id: 'Defect2',
    defect: 'dent',
    name: 'Dent / Crease',
    source: 'Filler',
    severity: 'Non-critical',
    reactionPlan: `
    1. Inspect
    2. Report
    `,
    color: '#D90429',
  },
  {
    id: 'Defect3',
    defect: 'impact',
    name: 'Impact / Dimple',
    source: 'Filler',
    severity: 'Non-critical',
    reactionPlan: `1. Inspect
    2. Report
    `,
    color: '#A9EFF3',
  },
  {
    id: 'Defect4',
    defect: 'foam',
    name: 'Foam',
    source: 'Seam leaker',
    severity: 'Critical',
    reactionPlan: `1. Stop the line
    2. Block the material
    3. Correct
    `,
    color: '#82D6B2',
  },
  {
    id: 'Defect5',
    defect: 'abrasion',
    name: 'Abrasion',
    source: 'Material',
    severity: 'Non-critical',
    reactionPlan: `1. Inspect
    2. Report
    `,
    color: '#CC5F21',

  },
  {
    id: 'Defect6',
    defect: 'misalign',
    name: 'Misalign',
    source: 'Material',
    severity: 'Critical',
    reactionPlan: `1. Stop the line
    2. Block the material
    3. Correct
    `,
    color: '#A1A1A1',

  },

  // {
  //   id: 'Defect',
  //   defect: '',
  //   name: '',
  //   source: '',
  //   severity: '',
  //   reactionPlan: `
  //   `,
  // },
  // {
  //   id: 'CANNOT_IDENTIFY',
  //   defect: "Can't Identify",
  //   name: 'Can not Identify',
  //   source: 'N/A',
  //   severity: 'Non-critical',
  //   reactionPlan: `N/A`,
  //   color: '#FFFFFF',
  // },
];

// type matchedDefect = {
//   id: string,
//   defect: string,
//   name: string,
//   source: string,
//   severity: string,
//   reactionPlan: string,
//   confidence: number,
// }

export const items = incidents.map((item: incident) => {
  const matchedDefects: any[]  = [];
  for(let i = 1; i < 6; i++) {
    const defectId = `Defect${i}`;   
    const d = defects.find(d => defectId === d.id)
    matchedDefects.push({id: defectId, confidence: item[defectId], ...d})
  }
  return { 
    id: item.ID.toString(10),
    critical: item.Critical === 1,
    controlPoint: item["Point of Control"],    
    dateTimeStart: item["Date-Time Start"],
    dateTimeEnd: item["Date-Time End"],
    timeStart: item["Time Start"],
    timeEnd: item["Time End"],
    timelineStart: item["Timeline Start"],
    timelineEnd: item["Timeline End"],
    line: item["Line"],
    brand: item["Brand"],
    images: item["Images"].split(' ').slice(0, 9).map((img: string) => img.replace('.jpeg', '')),
    verifiedDefect: item["Verified Defect"],
    defects: matchedDefects,
    showDefects: matchedDefects.filter((d: any) => d.confidence > 0.6),
  }
})
  // .filter(i => i.critical);
