import {incidents, incident} from './incidents';
import { format, parse } from 'date-fns';

type defect = {
  id: string,
  defect: string,
  name: string,
  source: string,
  severity: string,
  reactionPlan: string,
  color: string,
}

export const defects: defect[] = [
  {
    id: 'Defect1',
    defect: 'zdent',
    name: 'Z-Dent / Wrinkle',
    source: 'Filler',
    severity: 'Critical',
    reactionPlan: `
    1. Stop the filler;
    2. Inspect cans on the line;
    3. Follow the SOP #333 "Can Damage Reaction Plan";
    `,
    color: '#F4AC45',
  },
  {
    id: 'Defect2',
    defect: 'dent',
    name: 'Dent / Crease',
    source: 'Filler',
    severity: 'Non-critical',
    reactionPlan: `
    1. Stop the filler;
    2. Inspect cans on the line;
    3. Follow the SOP #333 "Can Damage Reaction Plan";
    `,
    color: '#D90429',
  },
  {
    id: 'Defect3',
    defect: 'impact',
    name: 'Impact / Dimple',
    source: 'Filler',
    severity: 'Non-critical',
    reactionPlan: `
    1. Stop the filler;
    2. Inspect cans on the line;
    3. Follow the SOP #333 "Can Damage Reaction Plan";
    `,
    color: '#A9EFF3',
  },
  {
    id: 'Defect4',
    defect: 'foam',
    name: 'Foam',
    source: 'Seam leaker',
    severity: 'Critical',
    reactionPlan: `
    1. Stop the filler;
    2. Block the product to last good check;
    3. Follow the SOP #332 "Seam Leaker Reaction Plan";
    `,
    color: '#82D6B2',
  },
  {
    id: 'Defect5',
    defect: 'abrasion',
    name: 'Abrasion',
    source: 'Material',
    severity: 'Non-critical',
    reactionPlan: `
    1. Inspect for coming material damage;
    2. Report to QA team;
    3. Follow the SOP #334 "Container Damage Reaction Plan";
    `,
    color: '#CC5F21',

  },
  {
    id: 'Defect6',
    defect: 'misalign',
    name: 'Misalign',
    source: 'Material',
    severity: 'Non-critical',
    reactionPlan: `
    No action required;
    `,
    color: '#A1A1A1',

  },

  // {
  //   id: 'Defect',
  //   defect: '',
  //   name: '',
  //   source: '',
  //   severity: '',
  //   reactionPlan: `
  //   `,
  // },
  {
    id: 'Defect7',
    defect: "cant-identify",
    name: 'Can not Identify',
    source: 'N/A',
    severity: 'Non-critical',
    reactionPlan: `N/A`,
    color: '#FFFFFF',
  },
];

// type matchedDefect = {
//   id: string,
//   defect: string,
//   name: string,
//   source: string,
//   severity: string,
//   reactionPlan: string,
//   confidence: number,
// }

const parseDate = (dateString: string) => {
  try {
    return `${format(parse(dateString, 'dd.MM.yy HH:mm:ss', new Date(2020, 1, 1)), 'hh:mm\u00A0aaaaa')}m`
  } catch(e) {
    return dateString;
  }
}

export const data = incidents.map((item: incident) => {
  const matchedDefects: any[]  = [];
  for(let i = 1; i < 8; i++) {
    const defectId = `Defect${i}`;   
    const d = defects.find(d => defectId === d.id)
    matchedDefects.push({id: defectId, confidence: item[defectId] ? item[defectId] : 0, ...d})
  }
  return { 
    id: item.ID.toString(10),
    critical: item.Critical === 1,
    controlPoint: item["Point of Control"],    
    dateTimeStart: item["Date-Time Start"],
    dateTimeEnd: item["Date-Time End"],
    // parse(item["Time Start"], 'dd.MM.yy hh:mm:ss')
    timeStart: parseDate(item["Date-Time Start"]),
    timeEnd: parseDate(item["Date-Time End"]),
    timelineStart: item["Timeline Start"],
    timelineEnd: item["Timeline End"],
    line: item["Line"],
    brand: item["Brand"],
    images: item["Images"].split(' ').slice(0, 9).map((img: string) => img.replace('.jpeg', '')),
    verifiedDefect: item["Verified Defect"],
    defects: matchedDefects,
    // showDefects: matchedDefects.sort((a,b) => b.confidence - a.confidence).slice(0, 1), //.filter((d: any) => d.confidence > 0.6),
    showDefects: matchedDefects.map(d => d)
      .sort((a,b) => b.confidence - a.confidence)
      .filter((d: any) => d.confidence > 0.6)
      .slice(0, 1),
    processed: false,
  }
})
  // .filter(i => i.critical);
