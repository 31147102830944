import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #D6D6D6;
`;
const Label = styled.div`
  position: absolute;
  top: 24px;
  color: #D6D6D6;
  background: #343434;
  padding: 4px 8px 8px 4px;
  margin-left: 8px;
`;
const Dot = styled.div`
  width: 12px;
  height: 12px;
  background: #FF0000;
  border-radius: 50%;
  margin-left: 20px;
  display: inline-block;
`;
function VideoLabel({label, children}: {label: React.ReactNode, children: React.ReactNode}) {
  return <Wrapper>
    <Label>{label} <Dot /></Label>
      {children} 
    </Wrapper>   
}

export default VideoLabel;