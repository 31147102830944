import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useDimensions from "react-use-dimensions";

import Table from 'modules/ShiftDashboard/Table';
import { items, defects } from 'containers/ShiftDashboard/data';

const SHIFT_START = 7;
const SHIFT_END = 15;

const TIMELINE_END = 1409;

type markerPositionType = {
  x: number;
  y: number;
}
type MarkerProps = {
  position: markerPositionType,
}
const BigFont = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
`;
const SmallFont = styled.div`
font-weight: normal;
font-size: 16px;
line-height: 24px;


`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Sidebar = styled.div`
  widTh: 20rem;
  background: #343434;
`;
const ProductTableWrapper = styled.div`
  height: 10rem;
`;
const TimelineTableWrapper = styled.div`
  height: 22rem;
  background: #343434;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 21rem);
  margin-left: 0.5rem;
  // margin-top: 0.5rem;
  background: #343434;
`;
const Header = styled.div`
  height: 2rem;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
`;
const Line = styled.div`
  height: 8rem;
  background: #505050;
  position: relative;
  cursor: pointer;
  background-image: url(/png/timeline_png3.png);
  background-repeat: no-repeat;
`;
const Marker = styled.div<MarkerProps>`
  position: absolute;
  background: #AB2323;
  width: 20px;
  height: 20px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  transform: rotate(-45deg);
  top: calc(50% - 10px);
  left: ${({position}) => position.x > TIMELINE_END ? TIMELINE_END - 10 : position.x - 10}px;
`;
const Time = styled.div`
  width: 100%;
  display: flex;
  background: #737373;
  cursor: pointer;
  height: 2rem;
  margin-bottom: 12px;
`;
const Hour = styled(BigFont)`
  width: 12.5%;
  margin-left: 8px;
`;
const DefectBar = styled.div`
  width: 100%;
  height: 24px;
  background: rgba(99, 99, 99, 0.45);
  border: 1px solid #696969;
  position: relative;
`;
type DefectProps = {
  color: string,
  left: number,
  width: number,
}
const Defect = styled.div<DefectProps>`
  position: absolute;
  height: 24px;
  top: 0;
  cursor: pointer;
  left: ${({left}) => left}px;
  width: ${({width}) => width}px;
  background: ${({color}) => color};  
  padding: 1px;
  transition: padding 300ms ease-in-out;
  &:hover {
    transition: padding 300ms ease-in-out;
    padding: 2px;
    border: 1px solid #fff;
  }
`;

const getHours = (i: number) => {
  if(i > 11) {
    return i > 12? `${i - 12}PM` : `${i}PM`;
  } 
  return `${i}AM`;
}
const getTimeLine = (from: number, to: number) => {
  return Array.apply(null, Array(to - from)).map((_, i) => getHours(from + i));
}
const wrapTimeLine = (timeline: string[]) => timeline.map((t) => <Hour key={`${t}`}>{t}</Hour>);

const calcStart = (timeStart: number, width: number) => {
  // const [hours, minutes, seconds] = timeStart.substr(9).split(':');
  // console.log(hours, minutes, seconds);
  // 13.10.20 10:00:07
  // const result = (SHIFT_START * 3600) - (parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds));
  // console.log(result, width);
  return width * timeStart;
};
const calcEnd = (timeStart: number, timeEnd: number | string, width: number) => {
  const end = typeof timeEnd === 'number' ? timeEnd : timeStart;
  return width * (end - timeStart);
};

function Timeline() {
  const [ref, {x, y, width}] = useDimensions();
  const [markerPosition, setMarkerPosition] = useState<markerPositionType>({x: TIMELINE_END, y: 0})
  const handleTimelineClick = (e: any) => {
    setMarkerPosition({x: (e.pageX - x), y: (e.pageY - y)})
  }
  // useEffect(() => {
  //   setMarkerPosition({x: 0, y: 0})
  // }, [])
  const defectTypes = defects.map((d) => <SmallFont key={d.id}>{d.name}</SmallFont>)
  return <Wrapper>
    <Sidebar>
      <ProductTableWrapper>
        <Table
            headers={['Product', 'CPM']}
            items={[
              ['Budweiser 16oz', '2,200'], 
            ]}
          />
      </ProductTableWrapper>

      <TimelineTableWrapper>
        <Table
          headers={['Point of control', 'Defect type']}
          items={[
            [<BigFont>FIller</BigFont>, 
              <>
                {defectTypes}
              </>], 
          ]}
        />
      </TimelineTableWrapper>      

    </Sidebar>
    <Body>
      <Header>Budweiser 16oz</Header>
      <Line ref={ref} onClick={handleTimelineClick}>
        <Marker position={markerPosition} />
      </Line>
      <Time onClick={handleTimelineClick}>
        {wrapTimeLine(getTimeLine(SHIFT_START, SHIFT_END))}
      </Time>
      {defects.map((d: any) => <DefectBar>
        {items.filter((i) => i.verifiedDefect === d.id).map((i) => {
          return <Defect
            color={d.color}
            left={calcStart(i.timelineStart, width)}
            width={calcEnd(i.timelineStart, i.timelineEnd, width)} />
        })}
      </DefectBar>)}
    </Body>
  </Wrapper>
}

export default Timeline;