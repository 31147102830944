import React from 'react';
import styled from 'styled-components';

type TableProps = {
  headers: React.ReactNode[],
  items: React.ReactNode[][],
}

const Table = styled.table`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #D6D6D6;
  display: inline-table;
  border-spacing: 0px;
`;
const Th = styled.th`
  background: #505050;
  font-size: 1rem;
  height: 2rem;
  font-weight: 500;
`;
const Td = styled.td`
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #696969;
  border-right: 1px solid #696969;
`;
const Tr = styled.tr`
  height: 2rem;
  &:nth-child(2n-1) ${Td} { 
    background: #131313;
  }
`;
function TableComponent({headers, items}: TableProps) {
  return <Table>
    {Array.isArray(headers) && <thead>
      <Tr>
        {headers.map((h, i) => <Th key={`${h}-${i}`}>{h}</Th>)}
      </Tr>
    </thead>}
    {Array.isArray(items) && <tbody>
      {items.map((row, i) => <Tr key={`${i}`}>
        {Array.isArray(row) && row.map((cell, j) => <Td key={`${j}`}>{cell}</Td>)}
      </Tr>)}
    </tbody>}
  </Table>
}

export default TableComponent;