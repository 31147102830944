import { InMemoryCache } from "@apollo/client";
// import { Todos } from './models/todos'

import { data } from 'models/Incidents';


// Create the todos var and initialize it with the initial value

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        incItems: {
          read () {
            return itemsVar();
          }
        }
      }
    }
  }
});
export const itemsVar = cache.makeVar(data)



export default cache;