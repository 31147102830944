import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import useDimensions from 'react-use-dimensions';

import XRayImage from 'components/XRayImage';
import RoundIcon from 'modules/Incidents/RoundIcon';
import ShowDefect from 'modules/Incidents/ShowDefect';

import { Text } from 'modules/Incidents/ListItem';

const Wrapper = styled.div`
  display: block;
  background: ${({theme}) => theme.incidents.list.itemBg};
  margin-left: 14px;
  width: 100%;
  height: 100%;
`;
const InnerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 24px 48px 24px;
`;
const Header = styled.div`
  display: flex;
  padding: 24px 0;
  align-items: center;
`
const BlackText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-left: 80px;
`;
const Product = styled(BlackText)`
  font-weight: bold;
`
const ControlPoint = styled(Product)`
  color: #00A3FF;
`;

const VDelimiter = styled.div`
  height: 54px;
  background: #989898;
  margin-left: 120px;
  margin-right: 80px;
  width: 2px;
`

const Time = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;
const Body = styled.div``;


const Delimiter = styled.div`
  margin: 24px 0;
  border: 2px solid #C5C5C5;
  width: 100%;
`;
function Details({item}: any) {
  const [ref, {height}] = useDimensions();
  const {images, controlPoint, showDefects, brand, timeStart, processed, verifiedDefect} = item; 
  const img = Array.isArray(images) ? images[0] : '';

  return <Wrapper ref={ref}>
      <Scrollbars 
        style={{height: height - 80}}
      >
        <InnerWrapper>
          <Header>
            <RoundIcon src={img}/>
            <Text>
              <BlackText>Product:</BlackText>
              <BlackText>Point of control:</BlackText>
              <BlackText>Defect type:</BlackText>
            </Text>
            <Text>
              <Product>{brand}</Product>
              <ControlPoint>{controlPoint}</ControlPoint>
              <BlackText><ShowDefect item={item} /></BlackText>
            </Text>
            <VDelimiter />
            <Time>Today at {timeStart}</Time>
          </Header>
          <Body>
            {Array.isArray(images)
              && images.map((img: string, i) => <React.Fragment key={img}>
                {i > 0 && <Delimiter></Delimiter>}
                
                <XRayImage  
                  img={img}
                  path="/images/incidents/"
                />
              </React.Fragment>)}
          </Body>
        </InnerWrapper>
      </Scrollbars>
    </Wrapper>
}

export default Details;