import React from 'react';
import styled from 'styled-components';

// import useFullscreen from "@rooks/use-fullscreen";

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${({theme}) => theme.header.height};

  width: 100%;
  padding: 0;
  background: ${({theme}) => theme.header.bg};
  color: ${({theme}) => theme.header.color};
`;
const LogoWrapper = styled.div`
  padding: 20px 18px;  
`;
const Logo = styled.img`
  width: 24px;
`;
const AppName = styled.div`
  padding: 20px 18px;  
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;
const Right = styled.img`
  position: absolute;
  right: 0;
`;
// const FullScreen = styled.button`
  
// `;

const LOGO = '/favicon.png';


function Header() {
  // const { toggle } = useFullscreen();
  return <Wrapper>
    <LogoWrapper>
      <Logo src={LOGO} />
    </LogoWrapper>
    <AppName>AB Inbev Quality Inspection</AppName>
    <Right alt="" src="/png/incident/header_right.png" />
    {/* <FullScreen onClick={() => toggle()}>Fullscreen</FullScreen> */}
  </Wrapper>
}

export default Header;
