import React, { useState } from 'react';
import styled from 'styled-components';

// type toggleProps = {
//   xRay: boolean,
// }


const Toggle = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  opacity: 0;
  background-image: url('/icons/toggle-icon.png');
  transition: opacity 500ms ease-in-out;
`;

const Title = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  height: auto;
  cursor: pointer;
  opacity: 0;
  padding: 12px 16px;
  background: #1b2635;
  color: #fff;
  
  transition: opacity 500ms ease-in-out;
`;
const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  
  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   width: 960px;
  //   height: 512px;
  //   background: #000;
  // }
  cursor: pointer;
  &:hover ${Toggle} {
    opacity: 1;
  }
  &:hover ${Title} {
    opacity: 1;
  }
`;
const XRay = styled.img`
  position: relative;
  width: 100%;
`;
// const Orig = styled.img<toggleProps>`
//   position: absolute;
//   width: 100%;
//   top: 0;
//   left: 0;
//   transition: opacity 500ms ease-in-out;
//   ${({xRay}) => xRay && css`
//     opacity: 0;
//   `}
// `;

const PREFIX_MAP = [
  {prefix: 'src', suffix: '.png', title: 'Source',},
  {prefix: 'pred', suffix: '.png', title: 'Prediction',},
  {prefix: 'dlt1', suffix: '.png', title: 'Delta1',},
  {prefix: 'dlt2', suffix: '.png', title: 'Delta2',},
]

function XRayImage({img, path}: {img: string, path: string}) {
  const [enabled, setEnabled] = useState(0);
  const nextId = enabled === 3 ? 0 : enabled + 1;

  const handleToggleClick = (e) => {
    e.stopPropagation();
    setEnabled(nextId);
  }
  const handleImageClick = () => {
    setEnabled(enabled > 0 ? 0 : 3);
  }

  const {prefix, suffix, title} = PREFIX_MAP[enabled]
  const src = `${path}${prefix}_${img}${suffix}`;
  return <ImageWrapper onClick={handleImageClick} >
    <XRay src={src} />
    <Title>{title}</Title>
    <Toggle onClick={handleToggleClick} />
  </ImageWrapper>
}

export default XRayImage;