import React from 'react';

function ShowDefect({item}) {
  const {processed, showDefects, verifiedDefect} = item;
  const defectToShow = processed ? verifiedDefect : showDefects.length > 0 
    ? showDefects.map(d => d.name).join(', ')
    : 'Unclassified';
  return <>{defectToShow}</>
}

export default ShowDefect;