import React  from 'react';
import styled from 'styled-components';
import { useReactiveVar  } from "@apollo/client";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import List from 'modules/Incidents/List';
import Header from 'modules/Incidents/Header';
import { itemsVar } from 'apollo/cache';
// import { GET_ALL_ITEMS } from 'operations';

// import Tabletop from "tabletop";
// import { GoogleSpreadsheet } from 'google-spreadsheet';
// import googleKey from 'utils/googleKey.json';

// const SPREADSHEET_ID = '11-aPgGBR4ddn9VWIhh0-HHpLcT7vgfQPbpAyOHKFcGc';
// const SHEET_ID = '1812203115';
// const CLIENT_EMAIL = googleKey.client_email;
// const PRIVATE_KEY = googleKey.private_key;
// const URL = 'https://docs.google.com/spreadsheets/d/11-aPgGBR4ddn9VWIhh0-HHpLcT7vgfQPbpAyOHKFcGc/edit#gid=1812203115';

// console.log(googleKey)


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
`;
function Incidents({match}: any) {
  // const [data, setData] = useState([]);

  // useEffect(() => {
        
    // Initialize the sheet - doc ID is the long id in the sheets URL
    // const doc = new GoogleSpreadsheet('<the sheet ID from the url>');

    // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
    // doc.useServiceAccountAuth({
    //   client_email: CLIENT_EMAIL,
    //   private_key: PRIVATE_KEY,
    // })
    //   .then(() => doc.loadInfo())
    //   .then(() => console.log(doc.title)) 
    

    // await doc.loadInfo(); // loads document properties and worksheets
    // await doc.updateProperties({ title: 'renamed doc' });

    // const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    // console.log(sheet.title);
    // console.log(sheet.rowCount);



  //   Tabletop.init({
  //     // key: PRIVATE_KEY,
  //     key: URL,
  //     simpleSheet: true,
  //   })
  //     .then((data: any) => setData(data))
  //     .catch((err: any) => console.warn(err));
  // }, []);
  
  const items = useReactiveVar<any>(itemsVar);
  
  return <Wrapper>
    <Header />
    <Switch>
      <Route 
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/${items[0].id}`} />}
      />
      <Route 
        path={`${match.path}/:id`}
        render={() => <List />}
      />
    </Switch>
  </Wrapper> 
}

export default Incidents;
