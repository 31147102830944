import React from 'react';
import styled from 'styled-components';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Incidents from 'containers/Incidents';
import ShiftDashboard from 'containers/ShiftDashboard';

//  height: ${({theme}) => `calc(${theme.module.height} - ${theme.header.height})`}; 
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({theme}) => `calc(${theme.module.width} - ${theme.sidebar.width})`};
  height: 100%;

  background: ${({theme}) => theme.module.bg};
  color: ${({theme}) => theme.module.color};
  overflow-y: auto;
  overflow-x: hidden;
  
  position: fixed;
  top: 64px;
  left: 64px;
`;

function Module() {
  const defaultRoute = '/incidents';
  return <Wrapper>
    <Switch>
      <Route path="/incidents" component={Incidents} />
      <Route path="/dashboard" component={ShiftDashboard} />

      <Route 
        path="/"
        render={() => <Redirect to={defaultRoute}/>} 
      />
    </Switch>
    </Wrapper>
}

export default Module;
